.root p {
  @apply t-body-base-blog mb-5 last:mb-0 md:mb-6;
}

.root a {
  @apply text-inherit;
}

.root strong {
  @apply font-semibold;
}

.root :is(h1, h2, h3, h4, h5, h6) a {
  @apply no-underline;
}

.root :is(h1, h2, h3, h4, h5, h6) {
  @apply mb-5 pt-3 first:pt-0 last:mb-0 md:mb-6 md:pt-4 xl:mb-9 xl:pt-8;
}

.root :is(h1, h2, h3, h4, h5, h6) strong {
  font-weight: unset;
}

.root h1 {
  @apply text-3xl text-error line-through; /* h1 should not be used */
}

.root h2 {
  @apply text-xl font-medium leading-7 xl:text-2xl xl:leading-8;
}

.root h3 {
  @apply text-lg font-medium leading-7 xl:text-xl;
}

.root :is(h4, h5, h6) {
  @apply text-base font-medium leading-6 xl:text-lg xl:leading-7;
}

.root table {
  @apply bg-error; /* table should not be used */
}

.root code {
  @apply rounded
  border-[0.5px]
  border-solid
  border-grey-scale-gray
  bg-grey-scale-light-gray
  px-1.5
  text-green-dark;
}
