.base {
  @apply relative;
}

.base :is(p, a) {
  @apply t-body-b2 mb-7 last:mb-0 md:mb-10;
}

.base p {
  @apply block;
}

.base table {
  @apply bg-red-600;
}

.base :is(h2, h3, h4, h5, h6) {
  @apply mb-7 block font-sans text-sm last:mb-0 2xl:text-base;
}
