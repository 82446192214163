.root {
  --list-items-gap: 16px;
  @apply [--list-pl:40px];
  @apply [--nested-list-pl:20px];
  @apply [--marker-left:calc(var(--list-pl)_*_-1)];
  @apply [--unordered-marker-top:9px] md:[--unordered-marker-top:10px];
  @apply [--nested-marker-left:-20px];
}

.root ul,
.root ol {
  @apply t-body-base-blog
  mb-5
  pl-[--list-pl]
  last:mb-0
  md:mb-6;
}

.root li {
  @apply relative
  mb-[--list-items-gap];
}

.root li:last-of-type {
  @apply mb-0;
}

.root li > :is(ol, ul):first-of-type {
  @apply mt-[--list-items-gap];
}

.root li > :is(ol, ul) {
  @apply mb-0
  pl-[--nested-list-pl];
}

.root li > ul > li:before {
  @apply left-[--nested-marker-left];
}

.root ul > li:before {
  @apply absolute
  left-[--marker-left]
  top-[--unordered-marker-top]
  size-1.5
  rounded-full
  bg-current
  content-[""];
}

.root ol {
  counter-reset: counter;
}

.root ol > li {
  counter-increment: counter;
}

.root ol > li:before {
  @apply absolute
  left-[--marker-left]
  top-0
  flex
  size-[25px]
  items-center
  justify-center
  rounded-full
  border
  border-solid
  border-current
  text-xs
  font-medium
  leading-4
  tracking-[0.02em]
  content-[counter(counter)];
}
